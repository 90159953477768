@import '../../styles/customMediaQueries.css';
.listingsContainer {
  composes: clearfix from global;
  /* border-top: 1px solid var(--colorGrey100); */
  padding-bottom: 23px;
}

.listingsTitle {
  color: var(--colorGrey700);
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 48px;
    margin-bottom: 8px;
  }
  @media (--viewportLarge) {
    margin-top: 56px;
    margin-bottom: 8px;
  }
}

.withBioMissingAbove {
  /* Avoid extra borders in mobile when bio is missing */
  border-top: none;

  @media (--viewportLarge) {
    /* border-top: 1px solid var(--colorGrey100); */
  }
}

.listings {
  /* margin: 0; */
  display: flex;
  flex-wrap: wrap;
  margin-left: 10px;
}

.listing {
  width: 100%;

  /* Single column in mobile */
  margin-top: 34px;

  @media (--viewportSmall) {
    /* Five columns in desktop */
    width: calc(20% - 12px);
    margin-bottom: 36px;

    margin-right: 12px;
    /* Vertical space */
    margin-top: 0;
  }

  @media only screen and (max-width: 600px) {
    width: calc(50% - 12px);
    margin-right: 10px;
  }
}

.latestProductsSection {
  background-color: white;
  margin: 50px 0;
}

.latestProductsSection h3 {
  text-align: center;
  color: black;
}
